import { types } from 'mobx-state-tree';
import { ImageModel } from '../sharedModels';

const ShopLocations = types.model('ShopLocations', {
	id: types.maybeNull(types.identifierNumber),
	telephoneNumbers: types.optional(types.array(types.string), []),
	location: types.optional(types.string, ''),
	workSchedule: types.optional(types.string, ''),
	images: types.optional(types.array(ImageModel), []),
});

const PaymentMethod = types.model('PaymentMethod', {
	id: types.maybeNull(types.identifierNumber),
	name: types.maybeNull(types.string),
	url: types.maybeNull(types.string),
});

const Shop = types.model('Shop', {
	id: types.maybeNull(types.identifierNumber),
	title: types.optional(types.string, ''),
	telephoneNumbers: types.optional(types.array(types.string), []),
	socialNetworkLinks: types.optional(types.array(types.string), []),
	description: types.maybeNull(types.string),
	images: types.optional(types.array(ImageModel), []),
	avatar: types.maybeNull(ImageModel),
	shopLocations: types.optional(types.array(ShopLocations), []),
	telegramShopBotUsername: types.optional(types.string, ''),
	status: types.optional(types.string, ''),
	contactUsUrl: types.optional(types.string, ''),
	minOrderAmount: types.maybeNull(types.number),
	language: types.optional(types.string, ''),
	currencySymbol: types.optional(types.string, ''),
	paymentMethods: types.optional(types.array(PaymentMethod), []),
	paymentTypes: types.optional(types.array(types.string), []),
	deliveryTypes: types.optional(types.array(types.string), []),
	enableFormatTitle: types.optional(types.boolean, false),
	isRenderBrand: types.optional(types.boolean, true),
	scripts: types.frozen(),
	enableConfirmationCall: types.optional(types.boolean, true),
});

export { Shop, ShopLocations };
